// gatsby-browser.js
import AuthProvider from './src/Auth0Provider';
import React from 'react';
import useAuthRedirect from './src/utils/hooks/useAuthRedirect';
import { SourceProvider } from './src/utils/SourceContext';
import { MetadataProvider } from './src/utils/MetadataContext';

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This tutorial has been updated. ` + `Reload to display the latest version?`
  );
  if (answer === true) {
    window.location.reload();
  }
};

export const wrapRootElement = ({ element }) => (
  <SourceProvider>
    <MetadataProvider>
      <AuthProvider>{element}</AuthProvider>
    </MetadataProvider>
  </SourceProvider>
);

export const wrapPageElement = ({ element, props }) => {
  const { componentWithProtectedRoute } = props.pageContext;

  if (componentWithProtectedRoute) {
    return (
      <AuthProvider>
        <ProtectedRoute>{element}</ProtectedRoute>
      </AuthProvider>
    );
  }

  return element;
};

const ProtectedRoute = ({ children }) => {
  const { isLoading, isAuthenticated } = useAuthRedirect();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? <div>{children}</div> : null;
};


export const onClientEntry = () => {
  const script = document.createElement("script")
  script.src = "https://www.googletagmanager.com/gtag/js?id=G-T9NKFXTSLN"
  script.async = true
  document.body.appendChild(script)

  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-T9NKFXTSLN');
}