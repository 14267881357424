// AuthProvider.js
import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import { domain, clientId } from './utils/auth0-config';

export const AuthProvider = ({ children }) => {

//   const domain = process.env.REACT_APP_AUTH0_DOMAIN;
//   const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const redirectUri = window.location.origin;
  const audience = "https://docs.xcaliber.health";
  const onRedirectCallback = (appState) => {
    window.history.replaceState({}, document.title, appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  };

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience: audience
      }}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
      
    >
      {children}
    </Auth0Provider>
  );
};
export default AuthProvider;
